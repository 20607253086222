export const MenuList = [

  {
    title: "Inicio",
    url: "/",
    url1: []
  },
  {
    title: "Productos",
    url: "/productos-relojesPared",
    url1: ["/productos-mochilas","/productos-todos","/productos-relojesPared","/productos-paraguas","/productos-relojesMano","/productos-lapiceras","/productos-otros"]
  },
  {
    title: "Trabajos Realizados",
    url: "/trabajos",
    url1: []
  },
  {
    title: "Sobre Nosotros",
    url: "/nosotros",
    url1: []
  },
  {
    title: "Contacto",
    url: "/contacto",
    url1: []
  },

];


const Paraguas = {
  paraguas: [
    {
      id: 1,
      path:"/productos-paraguas",
      cover: "./images/paraguas/1-paraguas.jpg",
      cover1: "./images/paraguas/1-paraguas-logo.jpg",
      cover2: "./images/paraguas/1-paraguas-colores.jpg",
      name: "Paragua Grande",
      description: "Este reloj de pared es un accesorio moderno y minimalista con bordes negros, hecho de plástico duro, resistente a golpes y caídas. Los números de tamaño normal son fáciles de leer, y funciona con una pila doble A. Fácil de instalar con su soporte para colgar, y su tamaño de 200x200mm lo hace perfecto para cualquier pared estándar. Un producto elegante y funcional para decorar cualquier ambiente.",
      price: "P1",
      discount: "2",
    },
    {
      id: 2,
      path:"/productos-paraguas",
      cover: "./images/paraguas/2-paraguas.jpg",
      cover1: "./images/paraguas/2-paraguas-detalle.jpg",
      cover2: "./images/paraguas/2-paraguas-colores.jpg",
      name: "Reloj Romano",
      description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vitae animi ad minima veritatis dolore. Architecto facere dignissimos voluptate fugit ratione molestias quis quidem exercitationem voluptas.",
      price: "P2",
      discount: "2",
    },
    {
      id: 3,
      path:"/productos-paraguas",
      cover: "./images/paraguas/3-paraguas.jpg",
      cover1: "./images/paraguas/3-paraguas-cerrado.jpg",
      cover2: "./images/paraguas/3-paraguas-colores.jpg",
      name: "Paragua Grande",
      description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vitae animi ad minima veritatis dolore. Architecto facere dignissimos voluptate fugit ratione molestias quis quidem exercitationem voluptas.",
      price: "P3",
      discount: "2",
    },
    {
      id: 4,
      path:"/productos-paraguas",
      cover: "./images/paraguas/4-paraguas.jpg",
      cover1: "./images/paraguas/4-paraguas-cerrado.jpg",
      cover2: "./images/paraguas/4-paraguas-colores.jpg",
      name: "Paragua Grande",
      description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vitae animi ad minima veritatis dolore. Architecto facere dignissimos voluptate fugit ratione molestias quis quidem exercitationem voluptas.",
      price: "P4",
      discount: "2",
    },
    {
      id: 5,
      path:"/productos-paraguas",
      cover: "./images/paraguas/5-paraguas.jpg",
      cover1: "./images/paraguas/5-paraguas-cerrado.jpg",
      cover2: "./images/paraguas/5-paraguas-colores.jpg",
      name: "Paragua Grande",
      description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vitae animi ad minima veritatis dolore. Architecto facere dignissimos voluptate fugit ratione molestias quis quidem exercitationem voluptas.",
      price: "P5",
      discount: "2",
    },
    {
      id: 6,
      path:"/productos-paraguas",
      cover: "./images/paraguas/6-paraguas.jpg",
      cover1: "./images/paraguas/6-paraguas-cerrado.jpg",
      cover2: "./images/paraguas/6-paraguas-colores.jpg",
      name: "Paragua Grande",
      description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vitae animi ad minima veritatis dolore. Architecto facere dignissimos voluptate fugit ratione molestias quis quidem exercitationem voluptas.",
      price: "P6",
      discount: "2",
    },
  ],
}
export default Paraguas

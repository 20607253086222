const Sdata = [
  {
    id: 1,
    title: "Unicamente ventas mayoristas",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis lobortis consequat eu, quam etiam at quis ut convallis.",
    cover: "./images/SlideCard/slide-1.jpg",
  },
  {
    id: 2,
    title: "Productos importados de real calidad",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis lobortis consequat eu, quam etiam at quis ut convallis.",
    cover: "./images/SlideCard/slide-2.jpg",
  },
  {
    id: 3,
    title: "Personalizacion de logos y estampados",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis lobortis consequat eu, quam etiam at quis ut convallis.",
    cover: "./images/SlideCard/slide-32.jpg",
  },
  {
    id: 4,
    title: "50% Off For Your First Shopping",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis lobortis consequat eu, quam etiam at quis ut convallis.",
    cover: "./images/SlideCard/slide-4.jpg",
  },
]
export default Sdata

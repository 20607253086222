const RelojesDeMano = {
  relojesDeMano: [
    {
      id: 1,
      cover: "./images/flash/flash-2.png",
      name: "Watch",
      price: 20,
      discount: 40,
    },
    {
      id: 2,
      cover: "./images/flash/flash-2.png",
      name: "Watch",
      price: 20,
      discount: 40,
    },
    {
      id: 3,
      cover: "./images/flash/flash-2.png",
      name: "Watch",
      price: 20,
      discount: 40,
    },
    {
      id: 4,
      cover: "./images/flash/flash-2.png",
      name: "Watch",
      price: 20,
      discount: 40,
    },
    {
      id: 5,
      cover: "./images/flash/flash-2.png",
      name: "Watch",
      price: 20,
      discount: 40,
    },
    {
      id: 6,
      cover: "./images/flash/flash-2.png",
      name: "Watch",
      price: 20,
      discount: 40,
    },
    {
      id: 7,
      cover: "./images/flash/flash-2.png",
      name: "Watch",
      price: 20,
      discount: 40,
    },
    {
      id: 8,
      cover: "./images/flash/flash-2.png",
      name: "Watch",
      price: 20,
      discount: 40,
    },
    {
      id: 9,
      cover: "./images/flash/flash-2.png",
      name: "Watch",
      price: 20,
      discount: 40,
    },
    {
      id: 10,
      cover: "./images/flash/flash-2.png",
      name: "Watch",
      price: 20,
      discount: 40,
    },
    {
      id: 11,
      cover: "./images/flash/flash-2.png",
      name: "Watch",
      price: 20,
      discount: 40,
    },
    {
      id: 12,
      cover: "./images/flash/flash-2.png",
      name: "Watch",
      price: 20,
      discount: 40,
    },
    {
      id: 13,
      cover: "./images/flash/flash-2.png",
      name: "Watch",
      price: 20,
      discount: 40,
    },
    {
      id: 14,
      cover: "./images/flash/flash-2.png",
      name: "Watch",
      price: 20,
      discount: 40,
    },
  ],
}
export default RelojesDeMano
